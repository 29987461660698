<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>评价管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 顶部搜索区域 -->
      <el-row :gutter="20">
        
        <el-col :span="4">
          <el-button type="primary" @click="addobj()">添加评价</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-input v-model="pages.goodsTitle" class="search-input" placeholder="商品名称" clearable />
          
          <el-select v-model="pages.groupId" class="search-select"  placeholder="关联分组">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <el-select v-model="pages.ifShow" class="search-select" placeholder="展示状态" >
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
          </el-select>
          <el-date-picker class="search-date" v-model="pages.beginTime" type="date" placeholder="开始时间" value-format="yyyy-MM-dd"></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="pages.endTime" type="date" placeholder="结束时间" value-format="yyyy-MM-dd"></el-date-picker>
          
          <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 商品列表区域 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left"
                     inline
                     class="demo-table-expand">
              <el-form-item label="用户名称：">
                <span>{{ props.row.userName}}</span>
              </el-form-item>
              <el-form-item label="用户头像：">
                <div v-if="!!props.row.userPic">
                  <el-image :src="props.row.userPic" :preview-src-list="[props.row.userPic]"
                 style="width: 100px; height: 100px; border: none" />
                </div>
              </el-form-item>
              <el-form-item label="凭证图片：">
                <div v-if="!!props.row.evaluatePics">
                  <el-image :src="item" v-for="(item,index) in props.row.evaluatePics.split(',')" :key="index" :preview-src-list="[item]"
                 style="width: 100px; height: 100px; border: none" />
                </div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="当页排序" width="80" />
        <el-table-column prop="createTime" label="提交时间" /> 
        <el-table-column prop="orderId" label="订单号" />
        <el-table-column prop="goodsTitle" label="商品名称" />
        <el-table-column label="商品图片">
            <template slot-scope="scope">
              <img v-if="scope.row.goodsPic" :src="scope.row.goodsPic.split(',')[0]" style="width: 60px; height: 60px; border: none" />
            </template>
          </el-table-column>
        <el-table-column prop="groupName" label="关联分组" />
        <el-table-column prop="specialRate" label="评分" >
          <template slot-scope="scope" >
            {{ scoreText[scope.row.comprehensiveScore] }}
          </template>
        </el-table-column>
        <el-table-column prop="evaluateMessage" label="评价内容" />
        <el-table-column label="展示状态">
          <template slot-scope="scope" >
            {{ scope.row.ifShow == 1 ? '展示中' : '未展示' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="addobj(scope.row)">编辑</el-button>
            <el-button type="danger" @click="removeGoodsById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="Pagination">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pages.currentPage"
                       :page-sizes="[1, 3, 10, 20]"
                       :page-size="pages.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      statusList:[
        {value: null, key: '全部'},
        {value: '1', key: '展示中'},
        {value: '0', key: '未展示'},
      ],
      scoreText: [
          '',
          '非常不满意',
          '不满意',
          '一般',
          '满意',
          '非常满意'
      ],
      list: [],
      
      // 分页
      pages: {
        currentPage: 1,
        pageSize: 10,
        ifShow: '',
        goodsTitle: '',
        groupId: '',
        beginTime: '',
        endTime: ''
      },
      groupList: [],
      total: 0,

    }
  },
  created () {
    this.loadData()
    this.$api.GetGoodsEvaluateGroupList({currentPage: 1, pageSize: 100,}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求商品失败')
        const list = [];
        this.groupList = [].concat([{name: '全部', id: null},], res.data);
    })
  },
  components: {
  },
  methods: {
    search(){
      let {beginTime, endTime} = this.pages;
      if((beginTime && !endTime) || (!beginTime && endTime)){
        alert('请输入完整的时间段')
        return
      }
      if(new Date(endTime).getTime() < new Date(beginTime).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      this.pages.currentPage = 1;
      this.loadData();
    },
    //  获取商品列表信息
    loadData () {
      // let ifShow = this.ifShow == 2 ? null : this.ifShow;
      const params = {...this.pages};
      this.$api.GetEvaluateList(params).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败')
        this.list = [...res.data.records]
      console.log(this.list)
        this.total = res.data.total
      })
    },

    // 监听页码大小 也就是下拉框
    handleSizeChange (newSize) {
      this.pages.pageSize = newSize
      // 刷新列表
      this.loadData()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange (newPage) {
      this.pages.currentPage = newPage
      // 刷新列表
      this.loadData()
    },

    // 点击跳转到添加商品列表
    addobj (data) {
      let p = '';
      if(data){
        p = '?id=' + data.id;
        var info = this.list.find(o => o.id == data.id);
        localStorage.setItem('comment-' + data.id, JSON.stringify(info));
      }
      this.$router.push('/addComment' + p)
    },

    // 点击显示出编辑商品对话框
    showEditDialog (row) {
      this.editForm = row
      console.log(this.editForm)
      this.editDialogVisible = true
    },

    // 监听分类编辑对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },

    // 编辑商品
    editGoodsInfo () {
      // console.log(123)
      this.$refs.editFormRef.validate((valid) => {
        // console.log(valid);
        if (!valid) return
        // 下面发起真正的请求
        this.$api.UpdateGoods(this.editForm).then((res) => {
          if (res.code !== 200) {
            // console.log();
            this.$message.error('修改商品分类失败')
          } else {
            this.$message.success('修改商品分类成功')
          }
          console.log(res)
          // 关闭对话框
          this.editDialogVisible = false
          // 刷新列表
          this.loadData()
        })
      })
    },

    setStatus(id, status){
      var type = status == 1 ? '上架' : '下架';
      this.$confirm('确定'+type+'商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        callback: action => {
          if (action === 'confirm') {
            this.$api.UpdateGoods2({id, status}).then((res) => {
              if (res.code !== 200) {
                this.$message.error(type + '商品失败')
              } else {
                this.$message.success(type + '商品成功')
              }
              this.loadData()
            })
            
          }
        }
      })
    },

    // 删除商品
    async removeGoodsById (id) {
      const confirmResult = await this.$confirm('此操作将永久删除, 是否继续?', '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') return
      this.$api.DeleteEvaluateFromGroup({id}).then((res) => {
        if (res.code !== 200) {
          this.$message.error('删除失败')
        } else {
          this.$message.success('删除成功')
          this.loadData()
        }
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
